/*eslint-disable*/
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Card, Input, Label, Button, Alert } from "reactstrap";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
var jsZip = require("jszip");
import CSVReader from "react-csv-reader";
import CsvDownloader from "react-csv-downloader";

import CustomBackButton from "components/Custom/Buttons/BackButton";
import CustomTextButton from "components/Custom/Buttons/TextButton";
import { csvToJson } from "utils";
import { jsZipToBase64 } from "./functions";
import { sendToCloudUpload } from "store/actions";

const columns = [
    { id: "name", displayName: "Name (required)" },
    { id: "description", displayName: "Description" },
    { id: "filename", displayName: "Filename (required)" },
    { id: "royalty", displayName: "Royalty (in %)" },
];

const datas = [
    {
        name: "NFT art 1",
        description: "For Testing Purpose",
        filename: "file.png",
        royalty: 2,
    },
];

const BulkNftUploadForm = () => {
    const dispatch = useDispatch();

    const [zipFile, setZipFile] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [error, setError] = useState(false);
    const [mismatchItems, setMismatchItems] = useState([]);

    const handleUploadButton = async () => {
        let zipData = [];
        let zipValues = [];

        if (!zipFile.length) {
            return toast.info("Select ZIP file");
        }

        if (!csvData.length) {
            return toast.info("CSV does not have contents");
        }

        await jsZip.loadAsync(zipFile[0]).then(function (zip) {
            zipValues = Object.values(zip.files);
            Object.keys(zip.files).forEach(function (filename) {
                // if (filename.replace("artworks/", "") !== "") {
                //     zipData.push(filename.replace("artworks/", ""));
                // }
                if (filename !== "") {
                    zipData.push(filename);
                }
            });
        });

        let errorFiles = [];
        const found = csvData.some((r) => {
            let result = zipData.indexOf(r.filename) >= 0;
            if (!result) {
                errorFiles.push(r.filename);
            }
        });

        if (errorFiles.length) {
            setMismatchItems(errorFiles);
            return setError(true);
        }

        let finalOutput = await Promise.all(
            csvData.map(async (e, i) => {
                let file = zipValues.filter((j) => j.name == e.filename);
                if (file.length) {
                    let base64Img = await jsZipToBase64(zipFile[0], file[0].name);
                    e.file = base64Img;
                    delete e.filename;
                    delete e.id;
                    return e;
                }
            })
        );

        dispatch(sendToCloudUpload({ data: finalOutput }));
    };

    const handleForce = (data, fileInfo) => {
        if (fileInfo?.size > 10000000) {
            return toast.info("File size is too large. Maximum supported file size is 10Mb");
        }

        let fields = {
            "Name (required)": "name",
            Description: "description",
            "Filename (required)": "filename",
            "Royalty (in %)": "royalty",
        };
        setError(false);

        let jsonData = csvToJson(data, fields);

        if (jsonData.length > 49) {
            return toast.info("CSV Holds rows greater than 50");
        }
        setCsvData(jsonData);
    };

    const getMismatchFiles = () => {
        return mismatchItems.map((e, i) => {
            return (
                <div key={i} style={{ display: "inline-block" }}>
                    <h5 style={{ fontSize: 15, fontWeight: "bold", paddingLeft: "3px" }}>{e}, </h5>
                </div>
            );
        });
    };

    return (
        <>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <div style={{ padding: "1em" }}>
                                <Grid container>
                                    <Grid item>
                                        <CustomBackButton />
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container fluid>
                <Grid container spacing={3}>
                    <Grid item xl={6} lg={6} sm={12} xs={12} md={12}>
                        <Card>
                            <div style={{ padding: "1em" }}>
                                <Grid container>
                                    <Grid item>
                                        <Label style={{ fontWeight: "bold" }}>
                                            Select CSV file contains the artwork details.
                                        </Label>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item>
                                        <Label style={{ fontWeight: "bold" }}>
                                            The maximum number of rows accepted is limited to 50
                                        </Label>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item>
                                        <Button style={{ width: "90%" }}>
                                            <CSVReader onFileLoaded={handleForce} />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ paddingTop: "10px" }}>
                                    <Grid item>
                                        <CsvDownloader filename="sample-csv" columns={columns} datas={datas}>
                                            <a href="#" style={{ color: "blue" }}>
                                                Download Sample
                                            </a>
                                        </CsvDownloader>
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xl={6} lg={6} sm={12} xs={12} md={12}>
                        <Card>
                            <div style={{ padding: "1em" }}>
                                <Grid container>
                                    <Grid item>
                                        <Label style={{ fontWeight: "bold" }}>Select zip file contains artworks</Label>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item>
                                        <Label style={{ fontWeight: "bold" }}>Maximum supported file size is 10Mb</Label>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item>
                                        <Button style={{ width: "100%" }}>
                                            <Input
                                                type="file"
                                                name="zipFile"
                                                accept=".zip"
                                                onChange={(e) => {
                                                    if (e.target.files[0].size > 10000000) {
                                                        return toast.info("File size is too large");
                                                    }
                                                    setZipFile(e.target.files);
                                                    setError(false);
                                                }}
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ paddingTop: "10px" }}>
                                    <Grid item>
                                        <a
                                            href={require("assets/sample-files/sample-zip.zip").default}
                                            download="sample-zip"
                                            style={{ color: "blue" }}
                                        >
                                            Download Sample
                                        </a>
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            {error ? (
                <Container fluid>
                    <Grid container>
                        <Grid item>
                            <div>
                                <Alert color="danger">
                                    <h4 className="alert-heading">Files not found on ZIP file</h4>
                                    <div>
                                        Files with name <span>{getMismatchFiles()}</span> not found on the ZIP file you have
                                        selected.
                                    </div>
                                    <hr />
                                    <p className="mb-0">Refresh this page and select the correct files.</p>
                                </Alert>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            ) : null}
            <Container fluid>
                <Grid container>
                    <Grid item>
                        <CustomTextButton title="upload" onClick={handleUploadButton} disabled={error} />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default BulkNftUploadForm;
