import Login from "views/auth/sign-in";
import SignUp from "views/auth/sign-up";
import BulkNftUploadForm from "views/bulk-nft-upload";
import NftDetails from "views/nft-details";
import NftList from "views/nft-list";
import SellNfts from "views/nft-list/sell-nfts";
import OwnNftList from "views/own-nft-list";
// import ReceivedNftList from "views/received-nft-list";
import TxnsList from "views/txns";
import UserPage from "views/user-profile";

var adminRoutes = [

    {
        path: "/nfts",
        name: "NFT collections",
        icon: "fas fa-images",
        component: NftList,
        layout: "/app",
        visibility: true,
    },
    // {
    //     path: "/received-nfts",
    //     name: "Received NFTs",
    //     icon: "fa fa-download",
    //     component: ReceivedNftList,
    //     layout: "/app",
    //     visibility: true,
    // },
    {
        path: "/own-nfts",
        name: "Own NFTs",
        icon: "fa fa-bookmark",
        component: OwnNftList,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/transactions",
        name: "Transactions",
        icon: "fas fa-money-check",
        component: TxnsList,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/profile",
        name: "Profile",
        icon: "fas fa-user",
        component: UserPage,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/nft/:nftId",
        name: "NFT Details",
        icon: "fas fa-user",
        component: NftDetails,
        layout: "/app",
        visibility: false,
    },
    {
        path: "/upload",
        name: "NFT Upload",
        icon: "fas fa-user",
        component: BulkNftUploadForm,
        layout: "/app",
        visibility: false,
    },
    {
        path: "/sell-nfts",
        name: "Sell NFT",
        icon: "fas fa-user",
        component: SellNfts,
        layout: "/app",
        visibility: false,
    },
];

var authRoutes = [
    {
        path: "/signin",
        name: "Log In",
        icon: "nc-icon nc-bank",
        component: Login,
        layout: "/auth",
    },
    {
        path: "/signup",
        name: "Sign Up",
        icon: "nc-icon nc-bank",
        component: SignUp,
        layout: "/auth",
    },
];

export { adminRoutes, authRoutes };
