import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "react-toastify/dist/ReactToastify.css";

import AdminLayout from "layouts/Admin";
import AuthLayout from "layouts/Auth";

import { history } from "utils";
import Landing from "views/landing";

const Main = () => {
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Router history={history}>
                <Switch>
                    <Route path="/app" render={(props) => <AdminLayout {...props} />} />
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    <Route path="/" render={(props) => <Landing {...props} />} />
                    <Redirect to="/auth/signin" />
                </Switch>
            </Router>
        </>
    );
};

export default Main;
