import { post } from "services";
import {
    TOGGLE_LOADING,
    LIST_ALL_NFTS,
    RESET_NFT_LIST_DATA,
    LIST_NFT_DETAILS,
    RESET_NFT_DETAILS_DATA,
    RESET_SELLING_ITEMS,
    SET_SELLING_ITEMS,
} from "../types";
import {
    FETCH_ALL_NFTS,
    CREATE_NEW_NFT,
    FETCH_NFT_DETAILS,
    TRANSFER_NFT_TO_EMAIL,
    TRANSFER_NFT_TO_WALLET,
    SEND_FOR_CLOUD_UPLOAD,
    SELL_NFTS,
    DELETE_NFT,
} from "graphql";
import { history } from "utils";
import { toast } from "react-toastify";
import { authorizedPost } from "./commonActions";
import { FETCH_ALL_RECEIVED_NFTS } from "graphql";
import { FETCH_ALL_OWN_NFTS } from "graphql";

export const listAllNfts = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { pageSize, pageNumber, list } = getState().nfts;

        let apiResponse = await post("", {
            query: FETCH_ALL_NFTS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_nfts: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_NFTS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const createNewNft = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await authorizedPost("", {
            query: CREATE_NEW_NFT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetNftsData());
            dispatch(listAllNfts());
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listNftDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_NFT_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_nft_details: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_NFT_DETAILS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const transferNftToEmail = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: TRANSFER_NFT_TO_EMAIL,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            history.push("/app/nfts");
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const transferNftToWallet = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: TRANSFER_NFT_TO_WALLET,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            history.push("/app/nfts");
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const sellNfts = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        let apiResponse = await post("", {
            query: SELL_NFTS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("Artwork successfully added for sales");
            history.push("/app/nfts");
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const sendToCloudUpload = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: SEND_FOR_CLOUD_UPLOAD,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("The data are successfully submitted to our server. Created NFTS will show up after 1 hour");
            history.push("/app/nfts");
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetNftsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_NFT_LIST_DATA });
    };
};

export const resetNftDetailsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_NFT_DETAILS_DATA });
    };
};

export const resetSellingNftItems = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_SELLING_ITEMS });
    };
};

export const setSellingNftItems = (data) => {
    return async (dispatch) => {
        dispatch({ type: SET_SELLING_ITEMS, payload: data });
    };
};

export const deleteAnNft = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: DELETE_NFT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("deleted successfully");
            history.push("/app/nfts");
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllReceivedNfts = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { pageSize, pageNumber, list } = getState().nfts;

        let apiResponse = await post("", {
            query: FETCH_ALL_RECEIVED_NFTS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_received_nfts: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_NFTS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllOwnNfts = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { pageSize, pageNumber, list } = getState().nfts;

        let apiResponse = await post("", {
            query: FETCH_ALL_OWN_NFTS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_own_nfts: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_NFTS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};