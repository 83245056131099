/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card } from "reactstrap";
import { Grid, Checkbox } from "@material-ui/core";
import { toast } from "react-toastify";

import CustomTable from "components/Custom/Tables/CustomTable";
import CustomModal from "components/Custom/Modal";

import { listAllOwnNfts, resetNftsData, resetSellingNftItems, setSellingNftItems } from "store/actions";
import CustomTextButton from "components/Custom/Buttons/TextButton";
import CustomBackButton from "components/Custom/Buttons/BackButton";
import SellingDetails from "./selling-details";

const SellNfts = () => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);

    const { nftsData, showViewMore, sellingItems } = useSelector(({ nfts: { list, showViewMore, sellingItems } }) => ({
        nftsData: list,
        showViewMore,
        sellingItems,
    }));

    useEffect(() => {
        dispatch(resetNftsData());
        dispatch(resetSellingNftItems());
        fetchMoreNfts();
    }, []);

    const fetchMoreNfts = () => {
        dispatch(listAllOwnNfts());
    };

    const handleSubmitButton = () => {
        if (!sellingItems.length) {
            return toast.info("Select at least one artwork");
        }
        setShowModal(true);
    };

    const handleCheckbox = ({ type = "", e }) => {
        if (type === "") {
            let data = sellingItems;
            let index = data.indexOf(e.target.name);

            if (index !== -1) {
                data.splice(index, 1);
                dispatch(setSellingNftItems(data));
                return;
            } else {
                data.push(e.target.name);
                dispatch(setSellingNftItems(data));
                return;
            }
        } else if (type === "all") {
            if (!sellingItems.length) {
                let a = nftsData.map((e) => e.nft_id);
                dispatch(setSellingNftItems(a));
                return;
            } else {
                dispatch(resetSellingNftItems());
                return;
            }
        } else {
            return;
        }
    };

    return (
        <>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <div style={{ padding: "1em" }}>
                                <Grid container>
                                    <Grid item>
                                        <CustomBackButton />
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <div style={{ padding: "1em" }}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <CustomTextButton
                                            title="Select All"
                                            onClick={() => handleCheckbox({ type: "all" })}
                                            disabled={!nftsData.length}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CustomTextButton
                                            title="Add for Sales"
                                            onClick={() => handleSubmitButton()}
                                            disabled={!nftsData.length}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <CustomTable
                            title="Art Works"
                            columns={[
                                {
                                    title: "Actions",
                                    render: (rowData) => (
                                        <Container>
                                            <Checkbox
                                                name={rowData.nft_id}
                                                onChange={(e) => handleCheckbox({ e: e })}
                                                checked={sellingItems.includes(rowData.nft_id)}
                                            />
                                        </Container>
                                    ),
                                },
                                { title: "Name", field: "name" },
                                { title: "NFT ID", field: "nft_id" },
                                {
                                    title: "Artwork",
                                    field: "art_url",
                                    render: (rowData) => <img src={rowData?.art_url} width={40} height={40} />,
                                },
                                { title: "Status", field: "status" },
                            ]}
                            data={nftsData}
                            showViewMoreButton={showViewMore}
                            viewMoreButtonClick={() => fetchMoreNfts()}
                        />
                    </Grid>
                </Grid>
            </Container>
            <CustomModal
                content={<SellingDetails nfts={sellingItems} dispatch={dispatch} />}
                visible={showModal}
                title="Selling details"
                handleClose={() => setShowModal(false)}
            />
        </>
    );
};

export default SellNfts;
