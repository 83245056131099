/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Grid } from "@material-ui/core";
// @material-ui/core components
import { CircularProgress as Loader } from "@material-ui/core";
import "./../styles.css";

import { logoutUser } from "store/actions";
import { history } from "utils";
import { Home } from "@material-ui/icons";

function SendOtpToVerify({ onSubmit }) {
  const dispatch = useDispatch();

  const { isLoading } = useSelector(({ loading }) => ({
    isLoading: loading,
  }));

  useEffect(() => {
    dispatch(logoutUser());
  }, []);

  return (
    <>
      <>
        <Button href="/" variant="outlined" className="back-button">
          <Home />
        </Button>
        <div className="login-box">
          <h2>Sign Up</h2>
          <Formik
            initialValues={{
              email: "",
              name: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              name: Yup.string().max(255).required("Name is required"),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="user-box">
                  <input
                    autoComplete="on"
                    type="text"
                    name="name"
                    defaultValue={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required={true}
                  />
                  {Boolean(touched.name && errors.name) ? (
                    <div
                      style={{
                        display: "block",
                        marginLeft: "10px",
                        color: "red",
                        fontSize: 13,
                      }}
                    >
                      {errors.name}
                    </div>
                  ) : (
                    ""
                  )}
                  <label>Name</label>
                </div>
                <div className="user-box">
                  <input
                    autoComplete="on"
                    type="email"
                    name="email"
                    defaultValue={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required={true}
                  />
                  {Boolean(touched.email && errors.email) ? (
                    <div
                      style={{
                        display: "block",
                        marginLeft: "10px",
                        color: "red",
                        fontSize: 13,
                      }}
                    >
                      {errors.email}
                    </div>
                  ) : (
                    ""
                  )}
                  <label>Email</label>
                </div>
                <Grid container justifyContent="center">
                  <Grid item>
                    <a href="#" onClick={handleSubmit}>
                      <span />
                      <span />
                      <span />
                      <span />
                      {isLoading ? (
                        <Loader color="inherit" size={20} />
                      ) : (
                        "SEND OTP"
                      )}
                    </a>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <br />
          <Grid container justifyContent="center">
            <Grid item>
              <p style={{ color: "grey" }}>
                Already registered?{" "}
                <a onClick={() => history.push("/auth/signin")}
                 style={{color:"white"}}>sign in here</a>
              </p>
            </Grid>
          </Grid>
        </div>
      </>
    </>
  );
}

export default SendOtpToVerify;
