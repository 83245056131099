import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "store/actions";
import { history } from "utils";
import Footer from "views/shared/footer";
import Navbar from "views/shared/navbar";

const buttonStyle = {
  marginLeft: "18px",
  backgroundColor: "#F9CA26",
  color: "black",
  border: 0,
};


function Landing() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logoutUser());
    });
    return (
        <div>
            <Navbar />
            {/* ***************************** Banner Starts Here **********************************/}
            <section
                className="container-fluid banner-container"
                style={{ backgroundColor: "rgb(26,27,40)", color: "white", height: "100vh", paddingTop: "100px" }}
            >
                <div className="container">
                    <div className="row banner-row">
                        <div className="col-md-6 banner-txt">
                            <h1>NFT Platform as a Service</h1>
                            <p>NFT infrastructure for businesses to handle rewards, incentives, beneﬁts & payouts</p>
                            <div className="btn-row row">
                  <button
                    onClick={()=> window.open("https://marketplace.funq.club/auth/listings","_self")
                    }
                    className="btn btn-outline-primary clr"
                    style={{marginLeft:"18px" ,color:"#F9CA26"}}
                  >
                    View marketplace
                  </button>

                  <button
                    onClick={() => history.push("/auth/signup")}
                    className="btn btn-primary clr"
                    style={buttonStyle}
                  >
                    Sign Up here
                  </button>
                </div>

                        </div>
                        <div className="col-md-6 banner-img">
                            <img src="assets/images/buy-sell.svg" alt="" />
                        </div>
                        <br />
                        &nbsp;
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Landing;
