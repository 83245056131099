/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
// @material-ui/core components
import { Box, Grid, CircularProgress as Loader, Button } from "@material-ui/core";

import { logoutUser } from "store/actions";
import { ArrowBack } from "@material-ui/icons";

function LoginWithOtp({ onSubmit }) {
    const dispatch = useDispatch();

    const { isLoading } = useSelector(({ loading }) => ({
        isLoading: loading,
    }));

    useEffect(() => {
        dispatch(logoutUser());
    }, []);

    return (
        <>
            <>
            <Button href="/auth/signin" variant="outlined" className="back-button">
                <ArrowBack/>
            </Button>
                <div className="login-box">
                    <h2>Sign In</h2>
                    <Formik
                        initialValues={{
                            otp: "",
                        }}
                        validationSchema={Yup.object().shape({
                            otp: Yup.string().max(255).required("OTP is required"),
                        })}
                        onSubmit={onSubmit}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="user-box">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        name="otp"
                                        defaultValue={values.otp}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required={true}
                                    />
                                    {Boolean(touched.otp && errors.otp) ? (
                                        <div
                                            style={{
                                                display: "block",
                                                marginLeft: "10px",
                                                color: "red",
                                                fontSize: 13,
                                            }}
                                        >
                                            {errors.otp}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <label>OTP</label>
                                </div>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                    <a href="#" onClick={handleSubmit}>
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        {isLoading ? <Loader color="inherit" size={20} /> : "SIGN IN"}
                                    </a>
                                </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </div>
                {/* <Grid container component={Box} marginTop="1rem">
                    <Grid item xs={6} component={Box} textAlign="left">
                        <a href="#admui" onClick={(e) => e.preventDefault()} className={classes.footerLinks}>
                            Forgot password
                        </a>
                    </Grid>
                </Grid> */}
            </>
        </>
    );
}

export default LoginWithOtp;
