/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
// @material-ui/core components
import { Grid, CircularProgress as Loader, Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import { logoutUser } from "store/actions";

function RegisterWithOtp({ onSubmit }) {
  const dispatch = useDispatch();

  const { isLoading } = useSelector(({ loading }) => ({
    isLoading: loading,
  }));

  useEffect(() => {
    dispatch(logoutUser());
  }, []);

  return (
    <>
      <>
        <Button href="/auth/signup" variant="outlined" className="back-button">
          <ArrowBack />
        </Button>
        <div className="login-box">
          <h2>Sign Up</h2>
          <Formik
            initialValues={{
              otp: "",
            }}
            validationSchema={Yup.object().shape({
              otp: Yup.string().required("OTP is required").max(255),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="user-box">
                  <input
                    autoComplete="off"
                    type="text"
                    name="otp"
                    defaultValue={values.otp}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required={true}
                  />
                  {Boolean(touched.otp && errors.otp) ? (
                    <div
                      style={{
                        display: "block",
                        marginLeft: "10px",
                        color: "red",
                        fontSize: 13,
                      }}
                    >
                      {errors.otp}
                    </div>
                  ) : (
                    ""
                  )}
                  <label>OTP</label>
                </div>
                <Grid container justifyContent="center">
                  <Grid item>
                    <a href="#" onClick={handleSubmit}>
                      <span />
                      <span />
                      <span />
                      <span />
                      {isLoading ? (
                        <Loader color="inherit" size={20} />
                      ) : (
                        "VERIFY"
                      )}
                    </a>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </>
    </>
  );
}

export default RegisterWithOtp;
